import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from "./store.js"
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import './directives'
import AlertServie from './services/errors'
import ApiService from './services/api'
global.Raphael = Raphael

Vue.config.productionTip = false
// Function to check for specific parameter
function getIdFromUrl () {
  // Create a new URLSearchParams object with the query string
  const params = new URLSearchParams(window.location.search)

  // Check if the 'id' parameter exists
  if (params.has('id')) {
    // Return the value of the 'id' parameter
    return params.get('id')
  } else {
    // Return null if the 'id' parameter is not found
    return null
  }
}
function loadVueApp () {
  const vm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')

  window.vm = vm
}

/*
 *  fetch lang, refresh endpoints before any initial load
 * */
;(async () => {
  ApiService.init()
  AlertServie.init()




  const id = getIdFromUrl('id')
  if (id) {
    await ApiService.refreshToken(id).finally(() => {
      loadVueApp()
    })
  } else {
    loadVueApp()
  }
})()
