import axios from "axios"
import router from "../router"
import AlertServie from "./errors"

const ApiService = {
  baseUrl: "https://fit-vibe.net:1337/api/v1",
  korekBaseUrl: "https://korek-he.trendy-technologies.com",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("fitVibToken"),
    lang: localStorage.getItem("lang") ?? "ar"
  },
  init () {
    localStorage.setItem("Lang", localStorage.getItem("lang") ?? "ar")

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null

        if (status === 401) {
          localStorage.removeItem("loggedIn")
          router.push("/login")
        } else if (status === 422) {
          error.response.data.errors.map(function (value, key) {
            AlertServie.errorMessage(error.response.data.errors[key][0])
          })
        } else if (status === 402) {
          AlertServie.errorMessage(error.response.data.message)
        } else if (status === 400) {
          AlertServie.errorMessage(error.response.data.message)
        } else {
          AlertServie.errorMessage("Error")
        }

        return Promise.reject(error)
      }
    )
  },
  changeLAng () {
    this.headers = {
      Authorization: "Bearer " + localStorage.getItem("fitVibToken"),
      lang: localStorage.getItem("lang") ?? "ar"
    }
  },
  async generateScript (uuid, timestamp) {
    delete axios.defaults.headers.common.lang
    try {
      const response = await axios.get(
        this.korekBaseUrl +
        "/dcbprotect.php?action=script&ti=" +
        uuid +
        "&ts=" +
        timestamp +
        "&te=%23sub_btn&servicename=highFit&merchantname=Tornet"
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async login (phoneNumber, ti) {
    axios.defaults.headers.common.lang =
      localStorage.getItem("lang") ?? "ar"

    try {
      const response = await axios.post(this.baseUrl + "/auth/subscribe", {
        msisdn: phoneNumber
      })
      if (response.data.data !== "") {
        localStorage.setItem("fitVibToken", response.data.data.access_token)
        this.headers = {
          Authorization: "Bearer " + response.data.data.access_token,
          lang: localStorage.getItem("lang")
        }
      }

      return response
    } catch (e) {
      return e.response
    }
  },

  async verify (phoneNumber, otp, referenceId) {
    try {
      const response = await axios.post(this.baseUrl + "/auth/verify", {
        msisdn: phoneNumber,
        referenceId: referenceId,
        pinCode: otp
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async checkUserStatus (phoneNumber) {
    try {
      const response = await axios.post(this.baseUrl + "/auth/check-user-status", {
        phoneNumber: phoneNumber
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async getSetting () {
    try {
      const response = await axios.get(this.baseUrl + "/site/getData")
      return response
    } catch (e) {
      return e.response
    }
  },

  async getCategories () {
    try {
      const response = await axios.get(this.baseUrl + "/category/list", { headers: this.headers })
      return response
    } catch (e) {
      return e.response
    }
  },

  async getLastContentCategories () {
    try {
      const response = await axios.get(this.baseUrl + "/content/content-per-category", { headers: this.headers })
      return response
    } catch (e) {
      return e.response
    }
  },

  async getContentByCategory (category) {
    try {
      const response = await axios.get(this.baseUrl + "/content/list-by-category?category_id=" + category, { headers: this.headers })
      return response
    } catch (e) {
      return e.response
    }
  },

  async openContent (contentID) {
    try {
      const response = await axios.get(this.baseUrl + "/content/openit/" + contentID, { headers: this.headers })
      return response
    } catch (e) {
      return e.response
    }
  },

  async myContents () {
    try {
      const response = await axios.get(this.baseUrl + "/content/subscriber-contents", { headers: this.headers })
      return response
    } catch (e) {
      return e.response
    }
  },

  async refreshToken (id) {
    return axios.get(`https://fit-vibe.net:1337/api/v1/auth/refreshToken/${id}`)
      .then(response => {
        if (response.data.data && response.data.code === 22) {
          if (response.data.data !== "" && response.data.data.access_token) {
            console.log(response, response.data.data.access_token)
            localStorage.setItem("fitVibToken", response.data.data.access_token)
            console.log(localStorage.getItem("fitVibToken"))
            this.headers = {
              Authorization: "Bearer " + response.data.data.access_token,
              lang: localStorage.getItem("lang")
            }
            window.location.href = '/'
          }
        }
      }

      )
  }

}


export default ApiService



